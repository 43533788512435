import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import "react-app-polyfill/ie11";

// Store
import store from "./store";

// Actions
import { getAllProducts, getAllPosts, closeQuickViewModal } from "./actions";

// Layouts
import Layout from "./components/app";

import Index5 from "./components/demoes/index5";

// Pages
import GlassCategories from "./components/main/pages/glass-categories/glass-categories";
import StoneCategory from "./components/main/pages/stone-category/stone-category";
import GlassCategoryProduct from "./components/main/pages/glass-categories/glass-category-products";
import Templating from "./components/main/pages/templating";
import TemplatingNew from "./components/main/pages/templating-new";

import Fabrication from "./components/main/pages/fabrication";
import Installation from "./components/main/pages/installation";
import TermsAndCondition from "./components/main/pages/terms-and-condition";
import FaqNew from "./components/main/pages/faq-new";
import AboutUs from "./components/main/pages/about-us";
import TestimonialsNew from "./components/main/pages/testimonials";
import PrivacyPolicy from "./components/main/pages/privacy-policy";
import ProductCatalogue from "./components/main/pages/product-catalogue/product-catalogue";
import StoneCatalogue from "./components/main/pages/product-catalogue/stone-catalogue";
import PageNotFound from "./components/main/pages/404";

import Contact from "./components/main/pages/contact";
import RequestACallBack from "./components/main/pages/request-a-callback";
import BookAppointment from "./components/main/pages/book-appointment";
import BookHomeVisit from "./components/main/pages/book-home-visit";
import BookShowroomVisit from "./components/main/pages/book-showroom-visit";
import ReqFreeConsult from "./components/main/pages/request-free-consultation";
import EnquireNow from "./components/main/pages/enquire-now";

// import Utils
import { initFunctions } from "./utils/utils";
import ProductDetail from "./components/main/product/product";
import GetAQuote from "./components/main/product/get-a-quote";
import EmptyShortList from "./components/main/product/empty-shortlist";
import EmptyWishList from "./components/main/product/empty-wishlist";
import no_record_found from "./components/main/product/empty-serach";

import Search from "./components/demoes/index5/search";
import SearchProduct from "./components/demoes/index5/searchProduct";

import LandingPage from "./components/demoes/index5/landing-page";
import GetAQuoteMobile from "./components/main/product/get-a-quote-mobile";
import ThankyouQuote from "./components/main/product/thankyou-quote";
import ThankyouWishlist from "./components/main/product/thankyou-wishlist";
// Blogs
import Blogs from "./components/main/blog/classic";
import Blog1 from "./components/main/blog/blog1";
import Blog2 from "./components/main/blog/blog2";
import Blog3 from "./components/main/blog/blog3";
import Blog4 from "./components/main/blog/blog4";
import Blog5 from "./components/main/blog/blog5";
import Blog6 from "./components/main/blog/blog6";
import Blog7 from "./components/main/blog/blog7";
import Blog8 from "./components/main/blog/blog8";
import Blog9 from "./components/main/blog/blog9";
import Blog10 from "./components/main/blog/blog10";
import Blog11 from "./components/main/blog/blog11";
import Blog12 from "./components/main/blog/blog12";
import Blog13 from "./components/main/blog/blog13";
import Blog14 from "./components/main/blog/blog14";
import Blog15 from "./components/main/blog/blog15";
import Blog16 from "./components/main/blog/blog16";
import Blog17 from "./components/main/blog/blog17";

//admin routes
import { ProtectedRoute } from "./components/dashboard/auth/protected.route";
import AdminDashboard from "./components/dashboard/pages/dashboard";
import adminLogin from "./components/dashboard/pages/auth/login";
import Dashboard from "./components/pages/dashboard";
import Suppliers from "./components/dashboard/pages/settings/suppliers";
import Customerdetails from "./components/dashboard/pages/settings/customers_details";
import Slabsize from "./components/dashboard/pages/settings/slab-size";
import Materialthickness from "./components/dashboard/pages/settings/material-thickness";
import ApplicationArea from "./components/dashboard/pages/settings/application-area";
import Materialcolors from "./components/dashboard/pages/settings/material-colors";
import DefineColors from "./components/dashboard/pages/settings/define-colors";
import Colorshades from "./components/dashboard/pages/settings/color-shades";
import Manufacturers from "./components/dashboard/pages/settings/manufacturers";
import CustomQuote from "./components/dashboard/pages/settings/custom-quote";
import Tilesize from "./components/dashboard/pages/settings/tile-size";
import Worktopoptions from "./components/dashboard/pages/settings/worktop-options";
import EdgeDetailOptions from "./components/dashboard/pages/settings/edge-detail-options";
import Finishes from "./components/dashboard/pages/settings/finishes";
import SuitableFor from "./components/dashboard/pages/settings/suitable-for";
import Effects from "./components/dashboard/pages/settings/effects";
import ExtraServices from "./components/dashboard/pages/settings/extra-services";
import Pages from "./components/dashboard/pages/settings/pages";
import CreatePage from "./components/dashboard/pages/settings/pages/create";
import CreatePageAuto from "./components/dashboard/pages/settings/pages/auto_pages";

import EditPage from "./components/dashboard/pages/settings/pages/edit";
import FacbricationOptions from "./components/dashboard/pages/settings/fabrication-options";
import MaterialNames from "./components/dashboard/pages/settings/material-names";
import Glasstype from "./components/dashboard/pages/settings/glass-type";
import Brands from "./components/dashboard/pages/settings/brands";
import Customers from "./components/dashboard/pages/settings/customers";
import Cutouts from "./components/dashboard/pages/settings/quote-parameters/glass/cutouts";
import SurveyFitOptions from "./components/dashboard/pages/settings/quote-parameters/glass/survey-fit-options";
import DesignOptions from "./components/dashboard/pages/settings/quote-parameters/glass/design-options";
import WorktopStone from "./components/dashboard/pages/settings/quote-parameters/stone/worktop-dimension";
import FabricationStone from "./components/dashboard/pages/settings/quote-parameters/stone/fabrication";
import SplashBackStone from "./components/dashboard/pages/settings/quote-parameters/stone/splashback";
import AddSku from "./components/dashboard/pages/settings/add-sku";
import skuList from "./components/dashboard/pages/settings/sku-list";
import quoteList from "./components/dashboard/pages/settings/quote-list";

import MaterialTypes from "./components/dashboard/pages/settings/material_types";
import MaterialSubType from "./components/dashboard/pages/settings/material-sub-type";

import editSku from "./components/dashboard/pages/settings/edit-sku-f";
import editQuote from "./components/dashboard/pages/settings/edit-quote";
import EditCustomer from "./components/dashboard/pages/settings/edit-customer";
import CustomerQuoteReport from "./components/dashboard/pages/settings/customer-quote-report";
import Wishlist from "./components/frontend/pages/wishlist";
import CustomPage from "./components/main/pages/custom-page";
import QuartzGranite from "./components/main/pages/quartz-granite";
import SpringSaleThankYou from "./components/frontend/pages/spring-sale-thank-you";
import bespoke_glass_splashbacks_London from "./components/frontend/pages/bespoke_glass_splashbacks_London";
import bespoke_glass_splashbacks_London_thank_you_page from "./components/frontend/pages/bespoke_glass_splashbacks_London_thank_you_page";
import bespoke_stone_worktops_London from "./components/frontend/pages/bespoke_stone_worktops_London";
import bespoke_stone_worktops_London_thank_you_page from "./components/frontend/pages/bespoke_stone_worktops_London_thank_you_page";
import quick_quote_kitchen_worktops from "./components/frontend/pages/quick-quote-kitchen-worktops";
import request_a_callback_thank_you_page from "./components/frontend/pages/request-a-callback-thank-you-page";
import request_free_consultation_thank_you_page from "./components/frontend/pages/request-free-consultation-thank-you-page";
import contact_thank_you_page from "./components/frontend/pages/contact-thank-you-page";
import book_home_visit_thank_you_page from "./components/frontend/pages/book-home-visit-thank-you-page";
import book_showroom_visit_thank_you_page from "./components/frontend/pages/book-showroom-visit-thank-you-page";
import quick_quote_kitchen_worktops_thank_you_pag from "./components/frontend/pages/quick-quote-kitchen-worktops-thank-you-page";
import quick_enquiry_homepage_thank_you_page from "./components/frontend/pages/quick-enquiry-homepage-thank-you-page";
export class Root extends Component {
  constructor(props) {
    super(props);
    initFunctions();
  }

  componentDidMount() {
    // close quick view modal if it is opened in new page
    store.dispatch(closeQuickViewModal());
  }

  render() {
    store.dispatch(getAllProducts());
    store.dispatch(getAllPosts());

    return (
      <Provider store={store}>
        <BrowserRouter basename={"/"}>
          <ScrollContext>
            <Switch>
              {/* LandingPage */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={Index5}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/home`}
                component={Index5}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/categories`}
                component={LandingPage}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/admin`}
                component={adminLogin}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/sku-list`}
                component={skuList}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/quote-list`}
                component={quoteList}
              />

              {/* <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/admin/edit-sku/:id`}
                  component={editSku}
                /> */}
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/edit-sku-f/:id`}
                component={editSku}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/edit-quote/:id`}
                component={editQuote}
              />

              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/dashboard`}
                component={AdminDashboard}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/suppliers`}
                component={Suppliers}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/admin/edit-customer`}
                component={EditCustomer}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/admin/customer-quote-report/:id`}
                component={CustomerQuoteReport}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/admin/add-sku`}
                component={AddSku}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/admin/sku-list`}
                component={skuList}
              />
              {/* <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/admin/edit-sku/:id`}
                  component={editSku}
                /> */}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/admin/edit-sku-f/:id`}
                component={editSku}
              />
              {/* <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/product-detail/:id`}
                  component={ProductDetail}
                /> */}

              {/* <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/product-detail/:id`}
                  component={ProductDetail}
                /> */}
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/cutouts`}
                component={Cutouts}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/splashbacks`}
                component={SplashBackStone}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/survey-fit-options`}
                component={SurveyFitOptions}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/design-options`}
                component={DesignOptions}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/worktop-dimensions`}
                component={WorktopStone}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/fabrication`}
                component={FabricationStone}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/material-colors`}
                component={Materialcolors}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/application-areas`}
                component={ApplicationArea}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/material-thickness`}
                component={Materialthickness}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/brands`}
                component={Brands}
              />

              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/worktop-options`}
                component={Worktopoptions}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/slab-size`}
                component={Slabsize}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/admin/color-shades`}
                component={Colorshades}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/material-types`}
                component={MaterialTypes}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/glass-category`}
                component={MaterialSubType}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/admin/define-colors`}
                component={DefineColors}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/admin/customers`}
                component={Customers}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/material-names`}
                component={MaterialNames}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/glass-type`}
                component={Glasstype}
              />

              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/tile-size`}
                component={Tilesize}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/edge-detail-options`}
                component={EdgeDetailOptions}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/finishes`}
                component={Finishes}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/suitable-for`}
                component={SuitableFor}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/effects`}
                component={Effects}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/admin/extra-services`}
                component={ExtraServices}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/admin/pages`}
                component={Pages}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/admin/create-page`}
                component={CreatePage}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/admin/create-page-auto`}
                component={CreatePageAuto}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/admin/edit-page/:id`}
                component={EditPage}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/fabrication-options`}
                component={FacbricationOptions}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/maunfacturers`}
                component={Manufacturers}
              />
              <ProtectedRoute
                exact
                path={`${process.env.PUBLIC_URL}/admin/custom-quote`}
                component={CustomQuote}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/admin/customers-details`}
                render={(props) => (
                  <Customerdetails {...props} title="customers details" />
                )}
              />

              <Layout>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/glass-categories`}
                  component={GlassCategories}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/stone-worktops`}
                  component={StoneCatalogue}
                />
                {/* if parms change then use this one */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/glass-category/:type`}
                  render={(props) => (
                    <GlassCategoryProduct
                      key={props.match.params.type}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/stone/:type`}
                  component={StoneCategory}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/templating`}
                  component={TemplatingNew}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/pages/:seo_url`}
                  component={CustomPage}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/quartz-granite-marble-worktops-london-sale`}
                  component={QuartzGranite}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/spring-sale-thank-you`}
                  component={SpringSaleThankYou}
                />
                {/* <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/TemplatingNew`}
                  component={TemplatingNew}
                /> */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/fabrication`}
                  component={Fabrication}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/installation`}
                  component={Installation}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/terms-and-condition`}
                  component={TermsAndCondition}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/faq`}
                  component={FaqNew}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/about-us`}
                  component={AboutUs}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/testimonials`}
                  component={TestimonialsNew}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/privacy-policy`}
                  component={PrivacyPolicy}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/product-catalogue`}
                  component={ProductCatalogue}
                />
                {/* Contact Pages */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/contact`}
                  component={Contact}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/request-a-callback`}
                  component={RequestACallBack}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/book-an-appointment`}
                  component={BookAppointment}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/book-home-visit`}
                  component={BookHomeVisit}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/book-showroom-visit`}
                  component={BookShowroomVisit}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/request-free-consultation`}
                  component={ReqFreeConsult}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/enquire`}
                  component={EnquireNow}
                />
                {/* Contact Pages */}
                {/* blogs start here */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blogs`}
                  component={Blogs}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/popular-types-glass-splashbacks-kitchens`}
                  component={Blog1}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/stone-kitchen-worktops-vs-glass-worktops`}
                  component={Blog2}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/10-things-know-kitchen-glass-splashbacks`}
                  component={Blog3}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/best-tips-tricks-clean-kitchen-glass-splashbacks`}
                  component={Blog4}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/pros-cons-printed-glass-splashbacks`}
                  component={Blog5}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/glass-splashbacks-north-south-east-west-london-supply-installation`}
                  component={Blog6}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/how-to-choose-the-right-glass-splashback-for-your-kitchen`}
                  component={Blog7}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/thinking-of-replacing-your-kitchen-worktop`}
                  component={Blog8}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/things-to-consider-when-choosing-a-material-for-your-kitchen-worktop`}
                  component={Blog9}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/the-ideal-thickness-for-your-quartz-worktop`}
                  component={Blog10}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/kitchen-worktop-looks-that-never-go-out-of-style`}
                  component={Blog11}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/5-kitchen-worktop-materials-that-are-easy-to-maintain`}
                  component={Blog12}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/five-home-remodeling-ideas`}
                  component={Blog13}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/pros-and-cons-of-granite-worktops`}
                  component={Blog14}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/best-qualities-of-marble-worktops`}
                  component={Blog15}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/5-things-to-consider-when-renovating-your-kitchen`}
                  component={Blog16}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/blog/pros-and-cons-of-quartz-worktops`}
                  component={Blog17}
                />
                {/* blogs end here */}
                {/* Pages belonged in Page */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/page/404`}
                  component={PageNotFound}
                />
                {/* Product Pages */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/product-detail/:manufacturer/:brand/:color/:id(\\d+)`}
                  component={ProductDetail}
                />

                {/* <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/product-detail/:manufacturer/:brand/:color/:productName/:id?`}
                  component={ProductDetail}
                /> */}

                {/* Quote Page */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/get-a-quote`}
                  component={GetAQuote}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/get-a-quote-mobile`}
                  component={GetAQuoteMobile}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/thanks-for-quote`}
                  component={ThankyouQuote}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/thanks-for-wishlist`}
                  component={ThankyouWishlist}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/empty-short-list`}
                  component={EmptyShortList}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/empty-wishlist`}
                  component={EmptyWishList}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/no-record-found`}
                  component={no_record_found}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/search/material=:material&category=:category&colour=:colour&product_name=:product_name&price_band=:price_band`}
                  render={(props) => (
                    <Search {...props} title="Search Result" />
                  )}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/q=:productName`}
                  render={(props) => (
                    <SearchProduct {...props} title="Search Result" />
                  )}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/search/material=:material&material_type=:material_type&effect=:effect&colour=:colour&brand=:brand&price_band=:price_band`}
                  render={(props) => (
                    <Search {...props} title="Search Result" />
                  )}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/wishlist`}
                  render={(props) => <Wishlist {...props} title="Wishlist" />}
                />
                {/* two static pages add here */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/bespoke_glass_splashbacks_London`}
                  component={bespoke_glass_splashbacks_London}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/quick-enquiry-glass-splashback-thank-you-page`}
                  component={bespoke_glass_splashbacks_London_thank_you_page}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/bespoke_stone_worktops_London`}
                  component={bespoke_stone_worktops_London}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/quick-enquiry-stone-worktop-thank-you-page`}
                  component={bespoke_stone_worktops_London_thank_you_page}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/quick-enquiry-homepage-thank-you-page`}
                  component={quick_enquiry_homepage_thank_you_page}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/quick-quote-kitchen-worktops`}
                  component={quick_quote_kitchen_worktops}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/request-a-callback-thank-you-page`}
                  component={request_a_callback_thank_you_page}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/request-free-consultation-thank-you-page`}
                  component={request_free_consultation_thank_you_page}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/contact-thank-you-page`}
                  component={contact_thank_you_page}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/book-showroom-visit-thank-you-page`}
                  component={book_showroom_visit_thank_you_page}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/quick-quote-thank-you-page`}
                  component={quick_quote_kitchen_worktops_thank_you_pag}
                />
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/book-home-visit-thank-you-page`}
                  component={book_home_visit_thank_you_page}
                />
              </Layout>
            </Switch>
          </ScrollContext>
        </BrowserRouter>
      </Provider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
