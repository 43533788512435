import React, { Component } from "react";
import { Helmet } from "react-helmet";

// import Custom Components
import Breadcrumb from "../../common/breadcrumb";

class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="main">
        <Helmet>
          <title>GNF - Privacy Policy</title>
        </Helmet>

        <Breadcrumb title="Privacy Policy" adClass="border-0 mb-0" />

        <div className="">
          <div className="container">
            <br />
            Last updated: (14/02/2020)
            <br />
            Our Company (Glass & Fusion Limited) ("us", "we", or "our") operates
            www.glassfusionltd.co.uk (.com) & www.granitesworktops.co.uk (.com).
            This page informs you of our policies regarding the collection, use
            and disclosure of Personal Information we receive from users of the
            Site.
            <br />
            We use your Personal Information only for providing and improving
            the Site. By using the Site, you agree to the collection and use of
            information in accordance with this policy.
            <br />
            <br />
            Information Collection And Use
            <br />
            While using our Site, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you. Personally, identifiable information may include, but
            is not limited to your name ("Personal Information")
            <br />
            <br />
            WHAT WE COLLECT
            <br />
            We may collect the following information:
            <br />• Full name and title
            <br />• contact information including email address
            <br />• demographic information such as postcode or address…
            <br />• other information relevant to customer surveys and/or offers
            <br />
            Purpose of the Processing
            <br />
            <br />
            Glass & Fusion Limited will process information provided by the USER
            for different purposes, depending on the way the data were gathered:
            <br />• To provide the services requested by the USER.
            <br />• To process, manage, provide, expand, and improve the
            services the USER has decided to subscribe to or register for.
            <br />• To adapt services to improve their quality for the USER.
            <br />• To design new services related to previous ones.
            <br />• To perform statistical studies that allow for design
            improvements in provided services.
            <br />• To send information required by the USER.
            <br />• To process job profiles, send by the USER.
            <br />• To manage the contractual and business relationship between
            THE COMPANY and USER as required.
            <br />• To send information regarding changes in products or
            services purchased by the USER.
            <br />• To send information regarding new or similar services to
            those originally purchased.
            <br />
            <br />
            Log Data
            <br />
            Like many site operators, we collect information that your browser
            sends whenever you visit our Site ("Log Data").
            <br />
            This Log Data may include information such as your computer's
            Internet Protocol ("IP") address, browser type, browser version, the
            pages of our Site that you visit, the time and date of your visit,
            the time spent on those pages and other statistics.
            <br />
            In addition, we may use third party services such as Google
            Analytics that collect, monitor and analyze this …
            <br />
            The Log Data section is for businesses that use analytics or
            tracking services in websites or apps, like Google Analytics. For
            the full disclosure section, create your own Privacy Policy.
            <br /> <br />
            Communications
            <br />
            We may use your Personal Information to contact you with
            newsletters, marketing or promotional materials and other
            information that ...
            <br />
            The Communications section is for businesses that may contact users
            via email (email newsletters) or other methods. For the full
            disclosure section, Glass & Fusion limited only disclose your
            information through any means if you are (USER) notified. Glass &
            Fusion will gather your information to provide you with our services
            and costs for our products and if you have subscribed to our
            automated service such news letters/ online quote. Those services
            are automated and will contact you directly from OUR SERVERS to
            provide you the requested service.
            <br />
            NO record will be stored outside glass & Fusion computers (
            online/counters PC) and it will not be shared with any third party,
            unless notified or subscribed to automated service (s).
            <br />
            <br />
            Cookies
            <br />
            Cookies are files with small amount of data, which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            web site and stored on your computer's hard drive.
            <br />
            Like many sites, we use "cookies" to collect information. You can
            instruct your browser to refuse all cookies or to indicate when a
            cookie is being sent. However, if you do not accept cookies, you may
            not be able to use some portions of our Site.
            <br />
            <br />
            Security
            <br />
            The security of your Personal Information is important to us, but
            remember that no method of transmission over the Internet, or method
            of electronic storage, is 100% secure. While we strive to use
            commercially acceptable means to protect your Personal Information,
            we cannot guarantee its absolute security.
            <br />
            <br />
            Changes To This Privacy Policy
            <br />
            This Privaczy Policy is effective as of (14/02/2020) and will remain
            in effect except with respect to any changes in its provisions in
            the future, which will be in effect immediately after being posted
            on this page.
            <br />
            We reserve the right to update or change our Privacy Policy at any
            time and you should check this Privacy Policy periodically. Your
            continued use of the Service after we post any modifications to the
            Privacy Policy on this page will constitute your acknowledgment of
            the modifications and your consent to abide and be bound by the
            modified Privacy Policy.
            <br />
            If we make any material changes to this Privacy Policy, we will
            notify you either through the email address you have provided us, or
            by placing a prominent notice on our website.
            <br />
            <br />
            Contact Us
            <br />
            If you have any questions about this Privacy Policy, please contact
            us on 02082468912 or through{" "}
            <a href="mailto:info@glassfusionltd.co.uk">
              info@glassfusionltd.co.uk
            </a>
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
