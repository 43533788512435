import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// import Custom Components
import Breadcrumb from "../../common/breadcrumb";

class Fabrication extends Component {
  render() {
    return (
      <div className="main">
        <Helmet>
          <title>GNF - Terms & Conditions</title>
        </Helmet>

        <Breadcrumb title="Terms & Conditions" adClass="border-0 mb-0" />

        <div className="">
          <div className="container">
            <br />
            <h6>General Trading Terms:</h6>
            <b>1- General</b>
            <br />
            <div style={{ marginLeft: "2%" }}>
              1.1- Those terms and conditions apply to all transactions between
              Glass & Fusion Limited company no: 10423564 of 5 Dawley Road,
              Hayes, UB3 1LS ( “we” and “us”) and the customer (“you”).
              <br />
              1.2- Terms and Conditions changes requires changes to be confirmed
              by writing by you as authorised agent at our Head Office 0208 243
              8912.
              <br />
              1.3- We may record calls for training and monitoring purposes
            </div>
            <br />
            <b>2- Variations</b>
            <br />
            <div style={{ marginLeft: "2%" }}>
              2.1- Natural stone products will be subject to natural variations,
              imperfections and sizes from any sample presented. There will be
              slight variations in Quartz stone (engineer stones). Unless goods
              are faulty, we accept no liability for any variances in this
              respect.
              <br />
              2.2- once product is been cut you are unable to cancel the order
              and any variation in sample versus actual product, you may inspect
              the products at our yard in Hayes, London.
              <br />
              2.3- Any change of requirements or variation after site
              survey/templating been made will be subject to an extra charge,
              while products need alteration to house the variation result
              placing your order on hold. Extra charges will be advised to cover
              the extra material and labouring.
              <br />
              2.4 we allow delays of fitting worktop up to 4 weeks, otherwise,
              we have the right to charge you 50+VAT weekly as “Storage cost”.
            </div>
            <br />
            <b>3- Cancellation</b>
            <br />
            <div style={{ marginLeft: "2%" }}>
              3.1- Orders cannot be cancelled after slabs/tiles been cut to your
              bespoke requirements, unless we are in breach of our obligation to
              you. Products are our property unless been paid in full subject to
              agreement.
              <br />
              3.2 Cancellation of an order must be told in writing.
              <br />
              3.3 After site survey and before cut all order is subject to match
              requirement and if site survey unsatisfactory, we reserve the
              right to hold £200+VAT before refund deposited to you.
            </div>
            <br />
            <b>3- Cancellation</b>
            <div style={{ marginLeft: "2%" }}>
              4.1 prices and other charges are in pounds sterling ( GBP)
              exclusive of VAT. V A T is chargeable and added to each and every
              order. We reserve rights to revise and amend prices & quotes
              without notice but we will confirm changes before making you
              commit or placing the order.
              <br />
              4.2- cheque payee should be made to “Glass & Fusion Limited”,
              payment over the phone 02039359199 or bank transfer. Bank Transfer
              to outside Glass & Fusion ltd bank accounts will be not considered
              unless confirmed with the office at London :Blanco Shop, 5 Dawley
              Road, Hayes, London UB3 1LS, info@glassfusionltd.co.uk ,
              02039359199.
              <br />
              4.3 A £200 deposit to book for site survey, not refundable after
              making the journey. 60% deposit is required to order the materials
              upon the date of templating and balance should be paid at the
              fitting date.
            </div>
            <br />
            <b>4- Payments</b>
            <div style={{ marginLeft: "2%" }}>
              4.1 prices and other charges are in pounds sterling ( GBP)
              exclusive of VAT. V A T is chargeable and added to each and every
              order. We reserve rights to revise and amend prices & quotes
              without notice but we will confirm changes before making you
              commit or placing the order.
              <br />
              4.2- cheque payee should be made to “Glass & Fusion Limited”,
              payment over the phone 02039359199 or bank transfer. Bank Transfer
              to outside Glass & Fusion ltd bank accounts will be not considered
              unless confirmed with the office at London :Blanco Shop, 5 Dawley
              Road, Hayes, London UB3 1LS, info@glassfusionltd.co.uk ,
              02039359199.
              <br />
              4.3 A £200 deposit to book for site survey, not refundable after
              making the journey. 60% deposit is required to order the materials
              upon the date of templating and balance should be paid at the
              fitting date.
            </div>
            <br />
            <b>5- Orders</b>
            <div style={{ marginLeft: "2%" }}>
              5.1 our standard lead time is 5 working days, lead time will
              change if you delay the payments, requirements changes. While lead
              time depends on number of factors, we will confirm your lead time
              after taking measurements.
              <br />
              5.2 lead time cannot be scheduled unless we have/receive 60%
              deposit, satisfactory site survey, confirmation of requirements
              and materials.
              <br />
              5.3 all products remain our property until you pay all the money
              you owe us.
              <br />
              5.4 late payment subject to interest rate of 4% and all settlement
              is overdue by 6 months subject to increase reflecting damage to
              us.
              <br />
              5.5 anyone places order on behalf of limited company agrees to be
              jointly and separately liable to us.
            </div>
            <br />
            <b>6- Delivery & installation</b>
            <div style={{ marginLeft: "2%" }}>
              6.1 Delivery date is an estimate only. We will endeavor to deliver
              all goods on the agreed/scheduled date. In the unlikely event that
              your order is not made available within 30 days of the agreed date
              you have the option to cancel the order in writing. However, a
              change of order will change the date of delivery and fitting.
              <br />
              6.2 items out of stock, we will inform you and forward estimate
              arrival time and date.
              <br />
              6.2.1 delivery only product need to be discussed before making the
              order. Delivery address may fall outside our coverage area and
              extra charges will be applied or advised upon making the order.
              Delivery only product subject to: you or your agent present at the
              time of delivery otherwise the driver retain the goods and
              re-delivery is chargeable at the same rate. Items delivered at you
              consent and left without you been present, we cannot accept
              responsibility for incorrect delivery or loss of goods or damages
              of goods.
              <br />
              6.3 in the event of circumstances beyond our control, we shall
              have no liability to you for any failure or delay.
              <br />
              6.4 any claim you make for faulty or poor workmanship must be
              notified in writing to us as soon as possible no later than 1
              working day. All fitting issue should be highlighted upon fitting
              while fitters onsite.
              <br />
              6.5 we have the right to repair chips on ceramic or compact
              surfaces such Dekton, Neolith, Lapitech, Laminam, classic
              porcelain …etc due to the manufacturing process and the nature of
              the material.
              <br />
              6.6 we may charge you reasonable costs if there is no defect or
              defect is not our responsibility, after we make journey or
              investigate the defect.
              <br />
              6.7 you or authorised person to act on your behalf in regard for
              your order should be onsite upon templating and fitting, while on
              installation any problem should be raised immediately to the
              fitters and cannot be hold responsible or liable for damages
              occurs during or after installation if you failed to make claim or
              raise concern.
            </div>
            <br />
            <b>7- Liability</b>
            <div style={{ marginLeft: "2%" }}>
              7.1 if you notify us of a problem, we will either replace the good
              or refund you for the good in question. Or repair the goods which
              are defective or damaged.
              <br />
              7.2 We shall have no liability to pay any money to you by way of
              compensation other than any refund we make under these conditions.
              Our liability to you shall not in any event include losses related
              to any business of yours, such as loss of profits or business
              interruption, neither will we be responsible to you for any other
              loss which is not a foreseeable consequence of us being in breach
              of these Terms and Conditions or our legal duties.
              <br />
              7.3 Once you have purchased your ‘man made’ worktop you must
              register your product with the manufacturer to claim your
              ‘Warranty’, you must have proof of receipt.
              <br />
              7.4 If you are a consumer, nothing in these Terms and Conditions
              will reduce your statutory rights relating to faulty or miss
              described goods or work that is not carried out to a reasonable
              standard. You should contact your local authority Trading
              Standards department or Citizens Advice if you need more
              information about your statutory rights.
              <br />
              7.5 Nothing in these Terms and Conditions is it intended to
              exclude our liability to you for fraudulent misrepresentation or
              for death or personal injury resulting from our negligence.
              <br />
              7.6 If you are a trade customer, we will not be responsible to you
              or, in the event that you are undertaking work for another person,
              to any other person, for the use or installation of any goods by
              you. Accordingly, if you are a trade customer, you agree to hold
              us harmless, and indemnify us against any liability associated
              with, any claim or allegation that we are responsible for any
              failings in the installation or use of goods that we supply.
            </div>
            <br />
            <b>8-</b>
            <div style={{ marginLeft: "2%" }}>
              This agreement is governed by English law and the English courts
              or by the law of the courts governing where we delivered the goods
              to if this is outside England or Wales.
            </div>
            <br />
            <b>Warranty</b>
            <br />
            Customer to activate the warranty for the materials through our
            suppliers, please contact us no later than two weeks of the date of
            installation for instructions.
            <br />
            Quartz warranty along with treated marble and granite does not
            cover:
            <br />
            <br />
            <b>Cracks</b> <br />
            Supplied stone worksurfaces will not crack unless excessive force
            has been placed upon them, uneven cabinets or uneven flooring,
            structural settling or movements, damage occurring from rapid
            changes in temperature (such as hot pots and pans being placed
            directly on the surface). This being the case cracks are not covered
            by this warranty.
            <br />
            <b>Abuse or Abnormal Use</b> <br />
            This includes but it not limited to mishandling, damage from heat,
            scorching, exposure to bad weather, ultraviolet light, chemical
            spillages, improper care as outlined in our Maintenance Guidelines
            or cracking/chipping/dropping items/breaking due to negligence.
            <br />
            <b>Marks and Colour Variation</b> <br />
            This includes fingerprints, smudges or metal marks made by utensils
            used in the kitchen.
            <br />
            <b>Wear and Tear</b> <br />
            This includes normal wear and tear that occurs over time, minor
            dents and scratches from kitchen utensil use/pots and pans, scuffs,
            chips, pouring boiling water onto the surface, dry ice or any of the
            instructions of care in our Maintenance Guide.
            <br />
            We require 48 Hours’ notice if you would like to cancel or
            re-arrange your agreed date for templating or fitting. There will be
            a charge of £200 if you give us less than 48 Hours’ notice to cancel
            your booking.
            <br />
            <br /> 1- We need 2 to 3 working days’ notice to book a template,
            please do not book a template if the kitchen installer has not
            agreed that they will be finished. Base unit and its end panels
            along with decorative fillers need to be fitted. Extractor fan and
            wall unit needs to be in place. If we are taking measurements for a
            Splash back. If you have wall units that “sit” on the worktop,
            please ensure they are fitted to allow sufficient tolerance (+3-4mm)
            for the worktops to slide in underneath them (or make arrangements
            for them to be simply removed/refitted flush once the worktops have
            been installed). Our fitters can assist but will obviously not as
            professionally taking responsibility and it may occur extra charge
            for any overtime spent in delaying our work.
            <br />
            2- It is a must that you or responsible person such project manager
            MUST BE ONSITE during the whole survey to discuss details and
            confirm requirements with surveyor such joints position (unless
            restricted), cut-out positions, tap holes, overhangs, up-stands,
            window sills and pipe boxes should be sited. If there are any
            additional issues to please advise the templator at this stage, as a
            return visit is expensive and chargeable.
            <br />
            3- All sinks, taps, hobs and any other appliances that will require
            cut-outs in the new worktops should be on site, but not necessarily
            fitted or connected – our templator will want to take away the paper
            templates provided in their packaging (templator will advise you on
            cuts need to be done to wooden unit of sink/hob if applicable those
            cuts and adjustment need to be done by CLIENT). If you are replacing
            worktops and wish to re-use your existing sink and hob or any other
            fitted appliances it is your responsibility to check with your
            builder/plumber that they can be easily removed/re-fitted without
            any damage, unless dismantle, disposal, reconnect service ordered it
            through us. It may be possible to fabricate a chopping board if
            there is any leftover over material. There is a charge of £60 per
            chopping board if requested on the templating date or before the
            slab has been cut. After we cannot guarantee the slab will be
            available and if it is available there will be a charge of £100 per
            chopping board.
            <br />
            4- Walls need to be plastered and squared but do not necessarily
            have to be tiled. Please advise the templator what you are intending
            to put on the walls. Obviously if your walls are not straight or
            flat this may affect the fitting of any upstands and splash backs.
            (See point 7 below for additional information on sealing to other
            surfaces)
            <br />
            5- If you want us to cut-out socket holes in splash backs or
            worktop, please advise the surveyor accordingly. Electric points
            should be fixed or marked at the height you want them. (We recommend
            allowing a minimum gap of 150mm from the base unit to the electric
            socket on 30mm worktops).
            <br />
            6- Our worktops are heavy – please advise our templator of any
            access, parking or other restrictions to the property and whether
            you can supply the fitters with temporary parking permits on fitting
            day. If the room is up/downstairs the templator may wish to measure
            the route to ensure there are no access issues on the day, please
            allow the templator to visit all required areas. For Health and
            Safety reasons our installers cannot remove their shoes or cover
            them.
            <br />
            <br />
            On fitting day: base units and any breakfast bars/islands must be
            ready, level and securely fitted. Obviously if your cupboards are
            not level to begin with your worktops will not be level either.
            <br />
            1- Any existing worktops – temporary or old – and any old tiling
            needs to have been removed. All sinks, taps, hobs and any other
            appliances should be on site. If you are planning to re-use your
            existing sink and hob or any other fitted appliances it is your
            responsibility to check with your own builder/plumber that they can
            be easily removed and re-fitted without any damage & they're ready
            for installation on fitting day.
            <br />
            2- Our fitters do not undertake plumbing or electrical works and are
            not certified for this type of work. Please call the office before
            work commences if you need assistance.
            <br />
            3- Please make sure all the doors below the worktop are removed and
            drawers have been removed and cupboards are empty – the fitters will
            need to fix the tops from inside the cupboards. This will also
            ensure there can be no damage to these items during installation.
            <br />
            4- IMPORTANT: Your base carcass units must be fit-for-purpose and
            strong enough to support the worktops. Some flat-pack kitchen units
            are only designed to hold Formica or composite worktops so
            supporting batons along the back wall and between large span may
            therefore be required, especially over and/or behind appliances -
            dishwashers, washing machines, tumble driers, sinks, hobs and any
            other weak points. This would be exactly the same advice for any
            solid worktops.
            <br />
            5- Our templator will have advised you of any strengthening work
            necessary but if he could not see behind your existing cupboards or
            appliances you must ask your builder to check this.
            <br />
            6- It is an industry standard that worktop fitters only fit
            worktops. They do not seal to other surfaces. It is the
            responsibility of your decorator to finish behind
            upstands/Splashback/etc... with caulk or filler to your preference.
            Please ask the fitters if you have any questions or would like to
            have them seal to other surfaces as part of the fit (a charge maybe
            payable for this).
            <br />
            7- A complete inspection will be carried out ahead of installation
            and photographs taken by the installers, then on completion another
            set of photographs will be taken to ensure no damage has been
            happened as a result of the worktop installation.
            <br />
            8- A sign off form will be produced that will need to be signed
            stating that you agree that the worktop has been fitted to standard
            and all worktop requirements is been met. You can add any comments
            to the sign off form. Please note that It is a must that you or
            responsible person such project manager MUST BE ONSITE during the
            whole fitting process and confirm requirements is met with fitters
            such joints, worktop & appliances support, cut-out positions, tap
            holes, overhangs, up-stands, window sills, splashbacks and
            waterfalls. If there are any issues to please advise fitters
            immediately, as a return visit is expensive and chargeable.
            <br />
            9- Finally, the fitters are required to collect your final payment
            on the day of the fitting – Payment method can be Cash, cheques,
            BACs & Card payment over the phone to our showroom Tel 0208 243
            8912. A copy of this will be emailed to you once the installer has
            returns to our offices along with your final proforma invoice once
            you have paid your final payment. All Glass & Fusion Products are
            our property unless been paid in full subject to agreement.
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }
}

export default Fabrication;
