import React, { Component } from "react";
import { FormTamplate } from "./settings_form";
import "../../style/index.scss";
import DashboardHeader from "../../includes/Header";
import axios from "axios";
import ImagePicker from "./../../includes/image-picker";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Redirect } from "react-router";

 // import "../../css/custome.scoped.css";

class AddSku extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finishImages: [],
      checkbox: false,
      inputValue: [],
      show: false,
      isRedirect: false,
      options: [],
      slabs: [],
      manufacturer: [],
      brands: [],
      brandsFilter: [],
      suppliers: [],
      suppliersFilter: [],
      base_color: [],
      color_shades: [],
      color_shadesFilter: [],
      material_types: [],
      tiles: [],
      applications: [],
      effects: [],
      thickness: [],
      finishes: [],
      glass_category: [],
      disabled: false,
      temporary: [],
      material: "Stone",
      file: "",
      imagePreviewUrl: "",
      selectedFinishes: [],
      getDateState: {
        name: "",
        warranty: "",
        video: "",
        description: "",
        additional_info: "",
        technical_info: "",
        manufacturer_id: "",
        material_sub_type_id: "",
        brand_id: "",
        supplier_id: "",
        base_color_id: "",
        color_shade_id: "",
        color_code: "",
        material_type_id: "",
        material_id: "",
        slab_size_note: "",
        slab_size: "",
        tile_size: "",
        tile_size_note: "",
        applications_area_note: "",
        applications_area: "",
        effects: "",
        effects_note: "",
        thicknesses: [],
        finishes: [],
      },
      images: {
        imagepreview_1: "http://placehold.it/180",
        imagepreview_2: "http://placehold.it/180",
        imagepreview_3: "http://placehold.it/180",
        imagepreview_4: "http://placehold.it/180",
        imagepreview_5: "http://placehold.it/180",
      },
      images_show: {
        imagepreview_1: "http://placehold.it/180",
        imagepreview_2: "http://placehold.it/180",
        imagepreview_3: "http://placehold.it/180",
        imagepreview_4: "http://placehold.it/180",
        imagepreview_5: "http://placehold.it/180",
      },
    };
    this.showGlass = this.showGlass.bind(this);
    this.showStone = this.showStone.bind(this);
    this.handleManufacturerChange = this.handleManufacturerChange.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this._handleImageChange = this._handleImageChange.bind(this);
    this.handleFinishesPrice = this.handleFinishesPrice.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.getDescription = this.getDescription.bind(this);
    this.getInformation = this.getInformation.bind(this);
    this.getTechnical = this.getTechnical.bind(this);
  }

  getDescription = (e, editor) => {
    let data = editor.getData();
    let getState = this.state.getDateState;
    getState["description"] = data;
    this.setState({
      ...this.state,
      getDateState: getState,
    });
  };

  getInformation = (e, editor) => {
    let data = editor.getData();
    let getState = this.state.getDateState;

    getState["additional_info"] = data;
    this.setState({
      ...this.state,
      getDateState: getState,
    });
  };

  getTechnical = (e, editor) => {
    let data = editor.getData();
    let getState = this.state.getDateState;

    getState["technical_info"] = data;
    this.setState({
      ...this.state,
      getDateState: getState,
    });
  };

  handleChangeeee(event) {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
  }

  handleFinishesPrice(e) {
    let finishes = this.state.selectedFinishes;
    let checkFinish = finishes.includes(e.target.getAttribute("name"));
    if (!checkFinish && e.target.value !== "") {
      finishes.push(e.target.getAttribute("name"));
      this.setState({
        ...this.state,
        selectedFinishes: finishes,
      });
    }
  }

  handleImageUpload(event) {
    let name = event.target.name;
    let saveImages = this.state.images;
    let saveImages1 = this.state.images_show;
    saveImages[name] = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        saveImages1[name] = e.target.result;
        this.setState({
          ...this.state,
          images_show: saveImages1,
          images: saveImages,
        });
      };

      reader.readAsDataURL(event.target.files[0]);
    }
    // this.setState({
    //   ...this.state,
    //   images: saveImages
    // });

    // if (event.target.files && event.target.files[0]) {
    //   let reader = new FileReader();
    //   reader.readAsDataURL(event.target.files[0]);
    //   reader.onload = (e) => {
    //     saveImages[name] = event.target.files[0];
    //     this.setState({
    //       ...this.state,
    //       images: saveImages
    //     });

    //   };
    // }
  }

  attactchFinishImage = (event) => {
    let finish = event.target.getAttribute("data-finish");
    let thickness = event.target.getAttribute("data-thickness");
    let file = event.target.files[0];
    let finishImages = this.state.finishImages;
    let index = finishImages.findIndex(function(item) {
      return item.finish == finish && item.thickness == thickness;
    });
    if (index === -1) {
      finishImages.push({
        finish: finish,
        thickness: thickness,
        image: file,
      });
    } else {
      finishImages.splice(index, 1);
      finishImages.push({
        finish: finish,
        thickness: thickness,
        image: file,
      });
    }
    this.setState({
      finishImages: finishImages,
    });
  };

  handleShow(event) {
    let name = event.target.name;
    let saveImages = this.state.images_show;

    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        saveImages[name] = e.target.result;
        this.setState({
          ...this.state,
          images_show: saveImages,
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.selectedFinishes.length == 0) {
      toast.warn(`Please select atlest 1 finish`);
    } else {
      this.setState({
        ...this.state,
        disabled: true,
      });

      let data = this.state.getDateState;
      let images = this.state.images;
      let finishImages = this.state.finishImages;

      const form = event.target;
      for (let element in data) {
        if (form[element] !== undefined) {
          if (
            element == "slab_size" ||
            element == "tile_size" ||
            element == "applications_area" ||
            element == "effects" ||
            element == "thicknesses" ||
            element == "inputValue" ||
            element == "img_caption" ||
            element == "sequence"
          ) {
            let localArray = [];
            var getSlabSizeArray = form[element];
            for (var i = 0; i < getSlabSizeArray.length; i++) {
              if (getSlabSizeArray[i].checked) {
                localArray.push(getSlabSizeArray[i].value);
              }
            }
            data[element] = localArray;
          } else if (element == "name") {
            data[element] = form[element][0].value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
          } else {
            data[element] = form[element].value;
          }
        } else {
        }
      }

      let finishesPrice = [];
      this.state.selectedFinishes.map((finish) => {
        let seprate = finish.split("_");
        let thickness_id = seprate[0];
        let finish_id = seprate[1];
        let price = form[finish].value;
        let tempObj = {
          thickness_id: thickness_id,
          finish_id: finish_id,
          price: price,
        };
        finishesPrice.push(tempObj);
      });

      let images_obj = [];
      for (let image in images) {
        if (this.state.images[image] !== "http://placehold.it/180") {
          let seprate = image.split("_");
          let image_id = seprate[1];
          let caption = form["imgcaption_" + image_id].value;
          let sequence = form["imgsequence_" + image_id].value;
          let tempImageObj = {
            caption: caption,
            sequence: sequence,
            image: this.state.images[image],
          };
          images_obj.push(tempImageObj);
        }
      }

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      let form_data = new FormData();

      for (var key in data) {
        form_data.append(key, data[key]);
      }

      for (var i = 0; i < finishesPrice.length; i++) {
        let finish = finishesPrice[i];
        form_data.append("finishes[" + i + "]", JSON.stringify(finish));
      }

      for (var i = 0; i < images_obj.length; i++) {
        let image = images_obj[i].image;
        let name = images_obj[i].image["name"].split(".");
        let details =
          name[0] +
          "--__" +
          images_obj[i]["caption"] +
          "--__" +
          images_obj[i]["sequence"];
        form_data.append("images[" + i + "]", image, details);
      }

      for (var i in finishImages) {
        let details = finishImages[i].thickness + "_" + finishImages[i].finish;
        form_data.append(
          "finish_image[" + i + "]",
          finishImages[i].image,
          details
        );
      }

      axios
        .post(`${process.env.API_URL}/sku`, form_data, config)
        .then((res) => {
          this.setState({
            isRedirect: true,
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    }
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      return this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
  }
  handleInput = (event, index) => {
    this.state.inputValue[index] = event.target.value;
    this.setState({
      ...this.state,
      inputValue: this.state.inputValue,
    });
  };
  handleCheckbox(e) {
    let value = e.target.checked;

    let finishIndex = parseInt(e.target.getAttribute("data-index"));
    let thicknessIndex = parseInt(e.target.getAttribute("data-thickness"));
    let thicknesses = this.state.thickness;
    let requiredObj = thicknesses[thicknessIndex];
    let finishes = requiredObj.finishes;
    finishes[finishIndex].checked = value;
    requiredObj.finishes = finishes;
    thicknesses[thicknessIndex] = requiredObj;
    this.setState({
      ...this.state,
      thickness: thicknesses,
    });
  }
  handleThicknessCheck = (e) => {
    let thicknessIndex = parseInt(e.target.getAttribute("data-index"));
    let thicknesses = this.state.thickness;
    let requiredObj = thicknesses[thicknessIndex];
    requiredObj.checked = !requiredObj.checked;
    thicknesses.splice(thicknessIndex, 1, requiredObj);
    this.setState({
      ...this.state,
      thickness: thicknesses,
    });
  };
  showStone() {
    this.setState({
      ...this.state,
      material: "Stone",
    });
  }
  showGlass() {
    this.setState({
      ...this.state,
      temporary: this.state.thicknes,
    });

    let thiknes = this.state.thickness.filter(function(el) {
      return el.name != "4mm";
    });
    this.setState({
      ...this.state,
      material: "Glass",
    });
  }

  handleManufacturerChange = function(manufacturer) {
    let manufacturerNumber = manufacturer;
    let localArray = this.state.brands.filter(function(element) {
      return element.manufacturer_id === parseInt(manufacturerNumber);
    });
    this.setState({
      ...this.state,
      brandsFilter: localArray,
      suppliersFilter: [],
    });
  };

  handleBrandChange(brand) {
    let localArray = [];
    this.state.suppliers.filter(function(element) {
      let checkBrandId = element.brands.filter(function(el) {
        return el.pivot.brand_id === parseInt(brand);
      });
      if (checkBrandId.length !== 0) {
        localArray.push(element);
      }
    });
    this.setState({
      ...this.state,
      suppliersFilter: localArray,
    });
  }

  handleColorChange(color) {
    let localArray = this.state.color_shades.filter(function(element) {
      return element.baseColor.id === parseInt(color);
    });

    this.setState({
      ...this.state,
      color_shadesFilter: localArray,
    });
  }

  //slab Size
  componentDidMount() {
    axios.get(`${process.env.API_URL}/slab_sizes`).then((slab_size) => {
      let slabs = slab_size.data.map((slab_size) => {
        //mapping
        return {
          name: slab_size.name,
          value: slab_size.id,
          material: slab_size.materialType.name,
        };
      });

      this.setState({
        ...this.state,
        isLoaded: true,
        data: slab_size.data,
        slabs: slabs,
      });
    });
    //tile
    axios.get(`${process.env.API_URL}/tile_sizes`).then((tile_size) => {
      let tiles = tile_size.data.map((tile_size) => {
        //mapping
        return {
          name: tile_size.name,
          value: tile_size.id,
          material: tile_size.materialType.name,
        };
      });

      this.setState({
        ...this.state,
        isLoaded: true,
        tiles: tiles,
      });
    });

    //manufacturers
    axios.get(`${process.env.API_URL}/manufacturer`).then((res) => {
      let manufacturers = res.data.map((manufacturer) => {
        //mapping
        return { label: manufacturer.name, value: manufacturer.id };
      });
      this.setState({
        ...this.state,
        isLoaded: true,
        manufacturer: manufacturers,
      });
    });

    //brands

    axios.get(`${process.env.API_URL}/brand`).then((brand) => {
      let brands = brand.data.map((_brand) => {
        //mapping
        return {
          label: _brand.name,
          value: _brand.id,
          manufacturer_id: _brand.manufacturer_id,
        };
      });
      this.setState({
        // using spread operator, you will need transform-object-rest-spread from babel or
        // another transpiler to use this
        ...this.state, // spreading in state for future proofing
        isLoaded: true,
        brands: brands,
      });
    });
    //suppliers
    axios.get(`${process.env.API_URL}/suppliers`).then((res) => {
      let supp = res.data.map((suppl) => {
        return { label: suppl.name, value: suppl.id, brands: suppl.brands };
      });
      this.setState({
        // using spread operator, you will need transform-object-rest-spread from babel or
        // another transpiler to use this
        ...this.state, // spreading in state for future proofing
        isLoaded: true,
        suppliers: supp,
      });
    });

    //Base Color
    axios.get(`${process.env.API_URL}/base_color`).then((res) => {
      let base_colors = res.data.map((_base_color) => {
        return { label: _base_color.name, value: _base_color.id };
      });
      this.setState({
        // using spread operator, you will need transform-object-rest-spread from babel or
        // another transpiler to use this
        ...this.state, // spreading in state for future proofing
        isLoaded: true,
        base_color: base_colors,
      });
    });

    //color shades
    axios.get(`${process.env.API_URL}/color_shades`).then((color_shades) => {
      let color_shade = color_shades.data.map((_color_shades) => {
        return {
          label: _color_shades.name,
          value: _color_shades.id,
          baseColor: _color_shades.baseColor,
        };
      });

      this.setState({
        // using spread operator, you will need transform-object-rest-spread from babel or
        // another transpiler to use this
        ...this.state, // spreading in state for future proofing
        isLoaded: true,
        color_shades: color_shade,
      });
    });
    //Material Types
    axios
      .get(`${process.env.API_URL}/material_types`)
      .then((material_types) => {
        let material_type = material_types.data.map((_material_type) => {
          return {
            label: _material_type.name,
            value: _material_type.id,
            material: _material_type.materials.name,
          };
        });
        this.setState({
          // using spread operator, you will need transform-object-rest-spread from babel or
          // another transpiler to use this
          ...this.state, // spreading in state for future proofing
          isLoaded: true,
          material_types: material_type,
        });
      });

    //application Areas
    axios
      .get(`${process.env.API_URL}/application_areas`)
      .then((application_area) => {
        let application = application_area.data.map((application_area) => {
          //mapping
          return {
            name: application_area.name,
            value: application_area.id,
            material: application_area.materialType.name,
          };
        });

        this.setState({
          ...this.state,
          isLoaded: true,
          applications: application,
        });
      });

    //metrial sub type
    axios.get(`${process.env.API_URL}/material_subtype`).then((category) => {
      let glassCategory = category.data.map((category) => {
        //mapping
        return { name: category.name, value: category.id };
      });

      this.setState({
        ...this.state,
        isLoaded: true,
        glass_category: glassCategory,
      });
    });

    //effects
    axios.get(`${process.env.API_URL}/effects`).then((effect) => {
      let effectss = effect.data.map((effect) => {
        //mapping
        return {
          name: effect.name,
          value: effect.id,
          material: effect.materialType.name,
        };
      });

      this.setState({
        ...this.state,
        isLoaded: true,
        effects: effectss,
      });
    });
    //Thickness & Finishes
    axios
      .get(`${process.env.API_URL}/material_thickness`)
      .then((material_thickness) => {
        axios.get(`${process.env.API_URL}/finishes`).then((finish) => {
          let finishees = finish.data.map((finish) => {
            return {
              checked: false,
              name: finish.name,
              value: finish.id,
              material: finish.materialType.name,
            };
          });

          let material_thicknesses = material_thickness.data.map(
            //mapping
            (material_thickness) => {
              return {
                checked: false,
                name: material_thickness.name,
                value: material_thickness.id,
                material: material_thickness.materialType.name,
                finishes: finish.data.map((finish) => {
                  return {
                    checked: false,
                    name: finish.name,
                    value: finish.id,
                    material: finish.materialType.name,
                  };
                }),
              };
            }
          );
          this.setState({
            ...this.state,
            isLoaded: true,
            thickness: material_thicknesses,
            temporary: material_thicknesses,
            finishes: finish.data,
          });
        });
      });

    this.setState({
      ...this.state,
      material: "stone",
    });

    document.getElementById("stone").click();
    
    document.getElementById("adminPanel").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/custome.scoped.css`);
  }

  componentWillUnmount() {
    document.getElementById("adminPanel").removeAttribute("href");
  }

  // handleManufacturerChange(manufacturer) {

  //    console.log(this.state);
  //   // let localArray = this.state.brands.filter(function(element) {
  //   //   return element.manufacturer_id === manufacturer.value;
  //   // });
  //   // console.log(localArray);

  // }

  render() {
    if (this.state.isRedirect) {
      return (
        <Redirect to={`${process.env.PUBLIC_URL}/admin/sku-list`} push={true} />
      );
    }
    const manufacturers = this.state.manufacturer;
    let brands = this.state.brandsFilter;
    const suppliers = this.state.suppliersFilter;
    const base_color = this.state.base_color;
    const color_shade = this.state.color_shadesFilter;
    const material_type = this.state.material_types;

    const glass_category = this.state.glass_category;
    const {
      slabs,
      tiles,
      applications,
      effects,
      thickness,
      finishes,
      material,
      manufacturer,
    } = this.state;

    let content = "";
    if (material == "Stone") {
      content = (
        <div>
          <div className="row">
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Manufacturer:</label>
                <select
                  name="manufacturer_id"
                  class="custome-select-box-sku"
                  onChange={(e) =>
                    this.handleManufacturerChange(e.target.value)
                  }
                  required
                >
                  <option className="custom-control-label" key="base" value="">
                    Select Manufacturer
                  </option>
                  {manufacturers.map((element) => {
                    return (
                      <option className="custom-control-label" key={element.value} value={element.value}>
                        {element.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Brand:</label>
                <select
                  name="brand_id"
                  class="custome-select-box-sku"
                  onChange={(e) => this.handleBrandChange(e.target.value)}
                  required
                >
                  <option key="base" value="">
                    Select Manufacturer First
                  </option>
                  {brands.map((element) => {
                    return (
                      <option key={element.value} value={element.value}>
                        {element.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Supplier:</label>
                <select
                  name="supplier_id"
                  class="custome-select-box-sku"
                  required
                >
                  <option key="base" value="">
                    Select Brand First
                  </option>
                  {suppliers.map((element) => {
                    return (
                      <option key={element.value} value={element.value}>
                        {element.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Main Color:</label>

                <select
                  name="base_color_id"
                  class="custome-select-box-sku"
                  onChange={(e) => this.handleColorChange(e.target.value)}
                  required
                >
                  <option key="base" value="">
                    Select Base Color
                  </option>
                  {base_color.map((element) => {
                    return (
                      <option key={element.value} value={element.value}>
                        {element.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Color Name:</label>

                <select
                  name="color_shade_id"
                  class="custome-select-box-sku"
                  required
                >
                  <option key="base" value="">
                    Select base Color First
                  </option>
                  {color_shade.map((element) => {
                    return (
                      <option key={element.value} value={element.value}>
                        {element.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>

          <div className="row">


            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Color Code:</label>
                <input
                  type="text"
                  className="form-control custom-control-text"
                  id="color-code"
                  name="color_code"
                  placeholder="Color Code"
                  style={{ height: "27px" }}
                  required
                />
              </div>
            </div>
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Material:</label>
                <select
                  name="material_type_id"
                  class="custome-select-box-sku"
                  required
                >
                  <option key="base" value="">
                    Select Material
                  </option>
                  {material_type.map((element) => {
                    if (element.material !== "Glass")
                      return (
                        <option key={element.value} value={element.value}>
                          {element.label}
                        </option>
                      );
                  })}
                </select>
              </div>
            </div>
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Product Name:</label>
                <input
                  type="text"
                  className="form-control custom-control-text"
                  id="product_name"
                  name="name"
                  placeholder="Product Name"
                  style={{ height: "27px" }}
                  required
                />
              </div>
            </div>
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">
                  Manufacturer's Warranty:
                </label>
                <input
                  type="text"
                  className="form-control custom-control-text"
                  id="warranty"
                  name="warranty"
                  placeholder="Product Warranty"
                  style={{ height: "27px" }}
                  required
                />
              </div>
            </div>
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Product Video URL:</label>
                <input
                  type="text"
                  className="form-control custom-control-text"
                  id="product_name"
                  name="video"
                  placeholder="e.g. https://www.youtube.com/watch?v=EngW7tLk6R8"
                  style={{ height: "27px" }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      content = (
        <div>
          <div className="row">
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Manufacturer:</label>
                <select
                  name="manufacturer_id"
                  className="custome-select-box-sku"
                  onChange={(e) =>
                    this.handleManufacturerChange(e.target.value)
                  }
                >
                  <option key="base" value="">
                    Select Manufacturer
                  </option>
                  {manufacturers.map((element) => {
                    return (
                      <option key={element.value} value={element.value}>
                        {element.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Brand:</label>
                <select
                  name="brand_id"
                  className="custome-select-box-sku"
                  onChange={(e) => this.handleBrandChange(e.target.value)}
                >
                  <option key="base" value="">
                    Select Manufacturer First
                  </option>
                  {brands.map((element) => {
                    return (
                      <option key={element.value} value={element.value}>
                        {element.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Supplier:</label>
                <select name="supplier_id" class="custome-select-box-sku">
                  <option key="base" value="">
                    Select Brand First
                  </option>
                  {suppliers.map((element) => {
                    return (
                      <option key={element.value} value={element.value}>
                        {element.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Glass Category:</label>
                <select
                  name="material_type_id"
                  class="custome-select-box-sku"
                  required
                >
                  <option key="base" value="">
                    Select Category
                  </option>
                  {material_type.map((element) => {
                    if (element.material === "Glass")
                      return (
                        <option key={element.value} value={element.value}>
                          {element.label}
                        </option>
                      );
                  })}
                </select>
              </div>
            </div>
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Glass Product:</label>
                <select
                  name="material_sub_type_id"
                  class="custome-select-box-sku"
                >
                  <option key="base" value="">
                    Select Glass Product
                  </option>
                  {glass_category.map((element) => {
                    return (
                      <option key={element.value} value={element.value}>
                        {element.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>


          <div className="row">
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Main Color:</label>

                <select
                  name="base_color_id"
                  class="custome-select-box-sku"
                  onChange={(e) => this.handleColorChange(e.target.value)}
                >
                  <option key="base" value="">
                    Select Base Color
                  </option>
                  {base_color.map((element) => {
                    return (
                      <option key={element.value} value={element.value}>
                        {element.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Color Name:</label>

                <select name="color_shade_id" class="custome-select-box-sku">
                  <option key="base" value="">
                    Select base Color First
                  </option>
                  {color_shade.map((element) => {
                    return (
                      <option key={element.value} value={element.value}>
                        {element.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Color Code:</label>
                <input
                  type="text"
                  name="color_code"
                  className="form-control custom-control-text"
                  id="color-code"
                  placeholder="Color Code"
                  style={{ height: "27px" }}
                />
              </div>
            </div>
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Product Name:</label>
                <input
                  type="text-area"
                  className="form-control custom-control-text"
                  id="product_name"
                  name="name"
                  placeholder="Product Name"
                  style={{ height: "27px" }}
                  required
                />
              </div>
            </div>
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">
                  Manufacturer's Warranty:
                </label>
                <input
                  type="text"
                  className="form-control custom-control-text"
                  id="warranty"
                  name="warranty"
                  placeholder="Product Warranty"
                  style={{ height: "27px" }}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div style={{width: '18%', flex: '18%', maxWidth: '18%', marginLeft: '1%' , marginRight: '1%'}}>
              <div className="form-group">
                <label className="custome_lable custome_lable_sku">Product Video URL:</label>
                <input
                  type="text"
                  className="form-control custom-control-text"
                  id="product_name"
                  name="video"
                  placeholder="e.g. https://www.youtube.com/watch?v=EngW7tLk6R8"
                  style={{ height: "27px" }}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;
    if (imagePreviewUrl) {
      $imagePreview = <img src={imagePreviewUrl} />;
    } else {
      $imagePreview = (
        <div className="previewText">Please select an Image for Preview</div>
      );
    }

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <section className="admin-dashboard">
            <div className="row col-md-12">
              <div className="col-md-3">
                <DashboardHeader />
              </div>
              <div className="col-md-9 " style={{ "margin-top": "20px", width: '80%',maxWidth: '80%',flex: '80%' }}>
                <main className="page-content card">
                  <div className="container-fluid-sku">
                    <div className="container" style={{maxWidth: '100%', width: '100%', paddingTop: '2%'}}>
                    <h5>Add New Sku</h5>
                      <hr className="quotebreakLine" />
                      <label className="custome_lable custome_lable_sku">Select Material Type</label>
                      <div className="row">
                        <div className="col-md-1" style={{display:'flex'}}>
                          <label className="custome_lable custome_lable_sku custome_select_metrial_type" >Stone:</label>
                          <input
                            type="radio"
                            className="form-control"
                            id="stone"
                            style={{ height: "1.5em" }}
                            onChange={this.showStone}
                            placeholder="Stone"
                            name="material_id"
                            value="2"
                          />
                        </div>
                        <div className="col-md-1" style={{display:'flex'}}>
                          <label className="custome_lable custome_lable_sku custome_select_metrial_type" >Glass:</label>
                          <input
                            type="radio"
                            className="form-control"
                            id="glass"
                            style={{ height: "1.5em" }}
                            placeholder="glass"
                            name="material_id"
                            onChange={this.showGlass}
                            value="1"
                          />
                        </div>
                      </div>

                      {content}
                    </div>
                    <div className=" head-clr4" style={{ marginBottom: "4px" }}>
                    
                    <div className="col-md-12 row" style={{marginLeft:'0px' , marginRight:'0px' , paddingLeft:'0px' , paddingRight:'0px'}}>
                      
                      <div className="col-md-6 col-sm-12 custome_table_add_sku" style={{    borderRight: '1px solid #c96'}}>
                        <div className="row head-clr">
                          <h3>Slab Sizes</h3>
                        </div>
                        <div className="row pl-4">
                          {" "}
                          {slabs.map((slab) => {
                            if (slab.material === this.state.material) {
                              return (
                                <div className="">
                                  <div className="custom-control custom-control-sku custom-control-inline custom-checkbox">
                                    <input
                                      type="checkbox"
                                      value={slab.value}
                                      class="custom-control-input"
                                      name="slab_size"
                                      id={"slab-" + slab.value}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={"slab-" + slab.value}
                                    >
                                      {slab.name}
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>

                        <label className="custome_lable custome_lable_sku" htmlFor="name">
                          Slab Size Notes
                        </label>
                        <input
                          name="slab_size_note"
                          type="text"
                          className="form-control custom-control-text"
                          id="name"
                          placeholder="Slab Size Notes"
                          required=""
                        />
                      </div>
                      <div className="col-md-6 col-sm-12 custome_table_add_sku">
                        <div className="row head-clr">
                          <h3>Tile Sizes</h3>
                        </div>
                        <div className="row pl-4">
                          {" "}
                          {tiles.map((tile) => {
                            if (tile.material === this.state.material) {
                              return (
                                <div className="">
                                  <div className="custom-control custom-control-sku custom-control-inline custom-checkbox">
                                    <input
                                      type="checkbox"
                                      value={tile.value}
                                      className="custom-control-input"
                                      name="tile_size"
                                      id={"tile-" + tile.value}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={"tile-" + tile.value}
                                    >
                                      {tile.name}
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>

                        <label className="custome_lable custome_lable_sku" htmlFor="name">
                          Tile Size Notes
                        </label>
                        <input
                          type="text"
                          className="form-control custom-control-text"
                          id="name"
                          name="tile_size_note"
                          placeholder="Tile Size Notes"
                          required=""
                        />
                      </div>
                      <div className="col-md-6 col-sm-12 custome_table_add_sku" style={{    borderRight: '1px solid #c96'}}>
                        <div className="row head-clr">
                          <h3>Application Areas</h3>
                        </div>
                        <div className="row pl-4">
                          {" "}
                          {applications.map((application) => {
                            if (application.material === this.state.material) {
                              return (
                                <div className="">
                                  <div className="custom-control custom-control-sku custom-control-inline custom-checkbox">
                                    <input
                                      type="checkbox"
                                      value={application.value}
                                      className="custom-control-input"
                                      name="applications_area"
                                      id={"application-" + application.value}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={
                                        "application-" + application.value
                                      }
                                    >
                                      {application.name}
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>

                        <label className="custome_lable custome_lable_sku " htmlFor="name">
                          Application Areas Notes
                        </label>
                        <input
                          type="text"
                          className="form-control custom-control-text"
                          id="name"
                          name="applications_area_note"
                          placeholder="Application Areas Notes"
                          required=""
                        />
                      </div>
                      <div className="col-md-6 col-sm-12 custome_table_add_sku">
                        <div className="row head-clr">
                          <h3>Effects</h3>
                        </div>
                        <div className="row pl-4">
                          {" "}
                          {effects.map((effect) => {
                            if (effect.material === this.state.material) {
                              return (
                                <div className="">
                                  <div className="custom-control custom-control-sku custom-control-inline custom-checkbox">
                                    <input
                                      type="checkbox"
                                      value={effect.value}
                                      className="custom-control-input"
                                      name="effects"
                                      id={"effect-" + effect.value}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={"effect-" + effect.value}
                                    >
                                      {effect.name}
                                    </label>
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </div>

                        <label className="custome_lable custome_lable_sku" htmlFor="name">
                          Effects Notes
                        </label>
                        <input
                          type="text"
                          className="form-control custom-control-text"
                          id="name"
                          name="effects_note"
                          placeholder="Effects Notes"
                          required=""
                        />
                      </div>
                    </div>
                    </div>
                    <div className=" head-clr4" style={{ marginBottom: "4px" }}>
                      <div className=" head-clr">
                        <h3>Thickness, Finishes & Prices</h3>
                        <br />
                      </div>

                      <div className="row  col-md-12" style={{marginLeft: '0px', marginRight: '0px', paddingLeft: '0px', paddingRight: '0px'}}>
                        {thickness.map((thicknes, thicknessIndex) => {
                          if (thicknes.material === this.state.material) {
                            return (
                              <React.Fragment>
                                <div className="border-matt">
                                  <div className="row col-md-12">
                                    <div className="custom-control custom-control-sku col-md-12 head-clr3 custom-control-inline custom-checkbox">
                                      <input
                                        type="checkbox"
                                        value={thicknes.value}
                                        className="custom-control-input"
                                        name="thicknesses"
                                        data-index={thicknessIndex}
                                        id={"thickness" + thicknes.name}
                                        onChange={this.handleThicknessCheck.bind(
                                          this
                                        )}
                                      />

                                      <label
                                        className="custom-control-label"
                                        htmlFor={"thickness" + thicknes.name}
                                      >
                                        {" "}
                                        {thicknes.name}
                                      </label>
                                    </div>
                                  </div>
                                {" "}
                                  {thicknes.finishes.map((item, index) => {
                                    if (item.material === this.state.material) {
                                      return (
                                        <React.Fragment>
                                          <div className="custom-control custom-control-sku custom-control-inline custom-checkbox">
                                            <input
                                              type="checkbox"
                                              value={
                                                thicknes.name + "_" + item.value
                                              }
                                              className="custom-control-input"
                                              name="finishes"
                                              id={
                                                thicknes.name +
                                                "_" +
                                                thicknessIndex +
                                                "_" +
                                                index
                                              }
                                              data-index={index}
                                              data-thickness={thicknessIndex}
                                              disabled={!thicknes.checked}
                                              checked={
                                                thicknes.checked && item.checked
                                              }
                                              onChange={this.handleCheckbox.bind(
                                                this
                                              )}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={
                                                thicknes.name +
                                                "_" +
                                                thicknessIndex +
                                                "_" +
                                                index
                                              }
                                            >
                                              {item.name}
                                            </label>
                                            <input
                                              type="number"
                                              placeholder="price"
                                              className="form-control custom-text"
                                              id={
                                                thicknes.name +
                                                "_" +
                                                index +
                                                "_" +
                                                thicknessIndex
                                              }
                                              data-index={index}
                                              name={
                                                thicknes.value +
                                                "_" +
                                                item.value
                                              }
                                              disabled={
                                                !(
                                                  item.checked &&
                                                  thicknes.checked
                                                )
                                              }
                                              onBlur={
                                                ((event) =>
                                                  this.handleInput(
                                                    event,
                                                    index
                                                  )) &&
                                                this.handleFinishesPrice.bind(
                                                  this
                                                )
                                              }
                                            />
                                            <span>
                                              <label
                                                htmlFor={
                                                  "file_" +
                                                  thicknes.value +
                                                  "_" +
                                                  item.value
                                                }
                                              >
                                                <i className="text-success fa fa-upload"></i>
                                              </label>
                                              <input
                                                type="file"
                                                accept="image/*"
                                                data-thickness={thicknes.value}
                                                data-finish={item.value}
                                                onChange={
                                                  this.attactchFinishImage
                                                }
                                                className="d-none"
                                                id={
                                                  "file_" +
                                                  thicknes.value +
                                                  "_" +
                                                  item.value
                                                }
                                              />
                                            </span>
                                          </div>
                                        </React.Fragment>
                                      );
                                    }
                                  })}
                                </div>
                              </React.Fragment>
                            );
                          }
                        })}
                      </div>
                    </div>

                    <div className=" head-clr4" style={{ marginBottom: "4px" }}>
                      <div className="head-clr">
                        <h3>Images</h3>
                        <br />
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div style={{ display: "table-caption;" }}>
                            <input
                              type="file"
                              accept="image/*"
                              name="imagepreview_1"
                              onChange={this.handleImageUpload}
                              className="image-upload-custome"
                            />

                            <img
                              src={this.state.images_show.imagepreview_1}
                              className="image-preview-custome"
                            />
                          </div>

                          <div className="img-caption cust-img-cap">
                            <input
                              type="text"
                              className="form-control custom-control-text"
                              name="imgcaption_1"
                              placeholder="Image Caption"
                              required={
                                this.state.images_show.imagepreview_1 !==
                                "http://placehold.it/180"
                                  ? true
                                  : false
                              }
                            ></input>
                          </div>
                          <div className="img-caption cust-img-cap">
                            <input
                              type="number"
                              placeholder="Sequence Number"
                              name="imgsequence_1"
                              className="form-control custom-control-text"
                              required={
                                this.state.images_show.imagepreview_1 !==
                                "http://placehold.it/180"
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div style={{ display: "table-caption;" }}>
                            <input
                              type="file"
                              accept="image/*"
                              name="imagepreview_2"
                              onChange={this.handleImageUpload}
                              className="image-upload-custome"
                            />

                            <img
                              src={this.state.images_show.imagepreview_2}
                              className="image-preview-custome"
                            />
                          </div>
                          <div className="img-caption cust-img-cap">
                            <input
                              type="text"
                              className="form-control custom-control-text"
                              name="imgcaption_2"
                              placeholder="Image Caption"
                              required={
                                this.state.images_show.imagepreview_2 !==
                                "http://placehold.it/180"
                                  ? true
                                  : false
                              }
                            ></input>
                          </div>
                          <div className="img-caption cust-img-cap">
                            <input
                              type="text"
                              placeholder="Sequence Number"
                              name="imgsequence_2"
                              className="form-control custom-control-text"
                              required={
                                this.state.images_show.imagepreview_2 !==
                                "http://placehold.it/180"
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div style={{ display: "table-caption;" }}>
                            <input
                              type="file"
                              accept="image/*"
                              name="imagepreview_3"
                              onChange={this.handleImageUpload}
                              className="image-upload-custome"
                            />

                            <img
                              src={this.state.images_show.imagepreview_3}
                              className="image-preview-custome"
                            />
                          </div>
                          <div className="img-caption cust-img-cap">
                            <input
                              type="text"
                              className="form-control custom-control-text"
                              name="imgcaption_3"
                              placeholder="Image Caption"
                              required={
                                this.state.images_show.imagepreview_3 !==
                                "http://placehold.it/180"
                                  ? true
                                  : false
                              }
                            ></input>
                          </div>
                          <div className="img-caption cust-img-cap">
                            <input
                              type="text"
                              placeholder="Sequence Number"
                              name="imgsequence_3"
                              className="form-control custom-control-text"
                              required={
                                this.state.images_show.imagepreview_3 !==
                                "http://placehold.it/180"
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div style={{ display: "table-caption;" }}>
                            <input
                              type="file"
                              accept="image/*"
                              name="imagepreview_4"
                              onChange={this.handleImageUpload}
                              className="image-upload-custome"
                            />

                            <img
                              src={this.state.images_show.imagepreview_4}
                              className="image-preview-custome"
                            />
                          </div>
                          <div className="img-caption cust-img-cap">
                            <input
                              type="text"
                              className="form-control custom-control-text"
                              name="imgcaption_4"
                              placeholder="Image Caption"
                              required={
                                this.state.images_show.imagepreview_4 !==
                                "http://placehold.it/180"
                                  ? true
                                  : false
                              }
                            ></input>
                          </div>
                          <div className="img-caption cust-img-cap">
                            <input
                              type="text"
                              placeholder="Sequence Number"
                              name="imgsequence_4"
                              className="form-control custom-control-text"
                              required={
                                this.state.images_show.imagepreview_4 !==
                                "http://placehold.it/180"
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div style={{ display: "table-caption;" }}>
                            <input
                              type="file"
                              accept="image/*"
                              name="imagepreview_5"
                              onChange={this.handleImageUpload}
                              className="image-upload-custome"
                            />

                            <img
                              src={this.state.images_show.imagepreview_5}
                              className="image-preview-custome"
                            />
                          </div>
                          <div className="img-caption cust-img-cap">
                            <input
                              type="text"
                              className="form-control custom-control-text"
                              name="imgcaption_5"
                              placeholder="Image Caption"
                              required={
                                this.state.images_show.imagepreview_5 !==
                                "http://placehold.it/180"
                                  ? true
                                  : false
                              }
                            ></input>
                          </div>
                          <div className="img-caption cust-img-cap">
                            <input
                              type="text"
                              placeholder="Sequence Number"
                              name="imgsequence_5"
                              className="form-control custom-control-text"
                              required={
                                this.state.images_show.imagepreview_5 !==
                                "http://placehold.it/180"
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" head-clr4" style={{ marginBottom: "4px" }}>
                      <div className="head-clr">
                        <h3>Product Description</h3>
                        <br />
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <br />
                          <CKEditor
                            className="ClassicEditor"
                            name="description"
                            editor={ClassicEditor}
                            onChange={this.getDescription}
                          />
                        </div>
                      </div>
                    </div>

                    <div className=" head-clr4" style={{ marginBottom: "4px" }}>
                      <div className="head-clr">
                        <h3>Additional Information</h3>
                        <br />
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <br />
                          <CKEditor
                            className="ClassicEditor"
                            name="additional_info"
                            editor={ClassicEditor}
                            onChange={this.getInformation}
                          />
                        </div>
                      </div>
                    </div>

                    <div className=" head-clr4" style={{ marginBottom: "4px" }}>
                      <div className="head-clr">
                        <h3>Technical Information</h3>
                        <br />
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <br />
                          <CKEditor
                            className="ClassicEditor"
                            name="technical_info"
                            editor={ClassicEditor}
                            onChange={this.getTechnical}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col text-center">
                      <button
                        type="submit"
                        className="btn btn-outline-primary-2 btn-round btn-more"
                        disabled={this.state.disabled}
                      >
                        Save Sku
                      </button>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </section>
        </form>
      </div>
    );
  }
}

export default AddSku;
