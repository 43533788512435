import React from "react";
import { Link } from "react-router-dom";

export default (props) => {
  const { img } = props.insta;
  return (
    <div className="feed-col">
      <div className="instagram-feed">
        <img
          src={process.env.PUBLIC_URL + "/" + img}
          alt="img"
          width="218"
          height="218"
          style={{ height: "100%" }}
        />
      </div>
    </div>
  );
};
